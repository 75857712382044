@import "../../assets/styles/utils";

.index-page{
    .header{
        &:hover,&.white{
            .header-cont{
                background-color: #fff;
                background-image: none;
                color: $color-text;
                box-shadow:0 5px 10px -5px rgba(0,0,0,.3);
            }
            .header-right{
                .nav{
                    >ul{
                        >li{
                            // &:hover{
                            //     .nav-title{
                            //         a{
                            //             color: $color-main;
                            //         }
                            //     }
                            // }
                            .nav-title{
                                a{
                                    color: $color-text;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-cont{
            background-color: transparent;
            box-shadow:none;
            background-image: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,0));
            color: #fff;
            transition: all .3s;
        }
        .header-right{
            .nav{
                >ul{
                    >li{
                        .nav-title{
                            a{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.index-banner{
    position: relative;
    @include res(height,100vh,(md:800px,sm:600px,xs:300px));
    .swiper-container{
        height: 100%;
        .swiper-slide {
            overflow:hidden;
            .slide-inner {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-size: cover;
                background-position: center;
            }
            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.2);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }
        }
    }
    .banner-text{
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 2;
        color: #fff;
        font-weight: bold;
        transform: translateY(-50%);
        width: 100%;
        @include res(font-size,70px, 30 / 70);
        @include res(text-align,right,(sm:center));
    }
    .swiper-contraller{
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 3;
        @include res(bottom,100px, 30 / 100);
        .container{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &::before{
                content: '';
                display: block;
                flex-grow: 1;
                height: 1px;
                background-color: rgba(255,255,255,.2);
            }
            .swiper-pagination{
                position: static;
                flex-shrink: 0;
                width: auto;
                color: #fff;
                @include res(margin-left,10px, 10 / 10);
            }
            .swiper-button{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @include res(margin-left,20px, 10 / 20);
                .swiper-button-prev,.swiper-button-next{
                    position: static;
                    margin: 0;
                    border: 1px solid #fff;
                    &:hover{
                        background-color: transparent;
                    }
                }
                .swiper-button-next{
                    @include res(margin-left,10px);
                }
            }
        }
    }
}

.index-search{
    background-color: $color-main;
    @include res(padding-top,70px, 0 / 70);
    .search-cont{
        align-items: center;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        .left-form{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            @include res(width,50%,(sm:100%));
            @include res(padding-bottom,0,(sm:20px,xs:10px));
            .search-img{
                flex-shrink: 0;
                @include res(display,null,(sm:none));
                @include res(width,35%);
                @include res(margin-right,50px, 30 / 50);
                img{
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            .search-form{
                flex-grow: 1;
                @include res(padding-top,45px, 10 / 45);
                .index-common-title{
                    @include res(display,null,(xs:none));
                }
                form{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-bottom: 2px solid $color-main-yellow;
                    button{
                        color: #fff;
                        background: none;
                        border: none;
                        cursor: pointer;
                        margin-right: 5px;
                        .iconfont{
                            @include res(font-size,22px, 18 / 22);
                        }
                    }
                    input{
                        flex-grow: 1;
                        background: none;
                        border: none;
                        color: #fff;
                        @include res(line-height,50px, 40 / 50);
                        &::-webkit-input-placeholder{
                            color: rgba(255,255,255,.6);
                        }
                        &::-moz-placeholder{   /* Mozilla Firefox 19+ */
                            color: rgba(255,255,255,.6);
                        }
                        &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
                            color: rgba(255,255,255,.6);
                        }
                        &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
                            color: rgba(255,255,255,.6);
                        }
                    }
                }
            }
        }
        .right-link{
            color: #fff;
            @include res(width,45.7%,(sm:100%));
            @include res(padding-bottom,0,(sm:20px,xs:10px));
            h1{
                font-weight: bold;
                @include res(font-size,20px, 16 / 20);
                @include res(margin-bottom,20px, 10 / 20);
            }
            .link{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: wrap;
                a{
                    display: inline-block;
                    border-radius: 3px;
                    border: 1px solid rgba(255,255,255,.5);
                    transition: all .3s;
                    @include res(margin-right,8px, 5 / 8);
                    @include res(margin-bottom,8px, 5 / 8);
                    @include res(padding-top,5px);
                    @include res(padding-bottom,5px);
                    @include res(padding-left,10px, 8 / 10);
                    @include res(padding-right,10px, 8 / 10);
                    &:hover{
                        border: 1px solid rgba(255,255,255,1);
                    }
                }
            }
        }
    }
}

.index-about{
    @include res(padding-bottom,100px, 10 / 100);
    .cont1{
        @include res(padding-top,100px, 20 / 100);
        @include res(padding-bottom,100px, 20 / 100);
        .common-title{
            span{
                color: #d4d4d4;
                @include res(font-size,30px, 16 / 30);
                @include res(margin-left,20px, 10 / 20);
            }
        }
        .left-text{
            align-items: flex-start;
            justify-content: space-between;
            @include res(display,flex,(sm:block));
            .text{
                flex-grow: 1;
                @include res(line-height, 2, 1.6 / 2);
                @include res(font-size,18px, 14 / 18);
                @include res(margin-top,10px, 0 / 10);
                @include res(margin-bottom,30px, 10 / 30);
            }
            .link{
                display: flex;
                justify-content: flex-end;
                white-space: nowrap;
                @include res(width,auto,(sm:100%));
                @include res(margin-left,10%,(sm:0));
                @include res(max-width,180px,(sm:100%));
                @include res(margin-top,20px, 0 / 20);
                // a{
                //     color: #fff;
                //     white-space: nowrap;
                //     display: block;
                //     background: url(./images/link_bg.png) no-repeat right center $color-main;
                //     background-size: auto 100%;
                //     transition: all .3s;
                //     @include res(max-width,180px, 130 / 180);
                //     @include res(padding-left,30px, 15 / 30);
                //     @include res(padding-right,80px, 35 / 80);
                //     @include res(padding-top,18px, 10 / 18);
                //     @include res(padding-bottom,18px, 10 / 18);
                //     @include res(margin-left,180px, 35 / 180);
                //     &:hover{
                //         transform: translateY(-5px);
                //         box-shadow: 3px 3px 10px rgba(0,0,0,.3);
                //     }
                // }
            }
        }
    }
    .cont2{
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        @include res(display,flex);
        // @include res(margin-top,100px,10 / 100);
        @include res(margin-bottom,70px,20 / 70);
        .item{
            flex-shrink: 0;
            @include res(width,22%,(xs:47%));
            @include res(margin-top,0,(xs:15px));
            .number-top{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                border-bottom: 1px solid $color-main;
                @include res(padding-bottom,10px, 5 / 10);
                .number{
                    display: flex;
                    align-items: center;
                    color: $color-main;
                    .jia{
                        @include res(line-height,60px, 30 / 60);
                        @include res(font-size,60px, 30 / 60);
                    }
                }
                .dw{
                    @include res(margin-top,10px, 5 / 10);
                    @include res(font-size,18px, 14 / 18);
                }
            }
            .number-bottom{
                @include res(padding-top,12px, 7 / 12);
                @include res(font-size,18px, 16 / 18);
            }
        }
    }
    .cont3{
        align-items: flex-start;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        .item{
            flex-shrink: 0;
            position: relative;
            background-color: #000;
            overflow: hidden;
            @include res(width,33.33%,(xs:100%));
            img{
                display: block;
                width: 100%;
                height: auto;
                opacity: 0;
            }
            b{
                background-size: cover;
                background-position: center;
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
                opacity: .8;
                transition: all .3s;
            }
            .text{
                position: absolute;
                z-index: 2;
                color: #fff;
                overflow: hidden;
                @include res(top,45%,(sm:20%,xs:50%));
                @include res(padding-left,35px, 15 / 35);
                @include res(padding-right,35px, 15 / 35);
                .title{
                    font-weight: bold;
                    @include res(margin-bottom,15px, 10 / 15);
                    @include res(font-size,30px, 18 / 30);
                }
                .t{
                    line-height: 1.6;
                    transition: all .3s;
                    @include res(transform,translateY(100%),(sm:translateY(0)));
                    @include res(font-size,18px, 14 / 18);
                }
            }
            &:hover{
                b{
                    transform: scale(1.1);
                }
                .text{
                    .t{
                       transform: translateY(0); 
                    }
                }
            }
        }
    }
}

.index-brand{
    background-color: $color-main-bg;
    @include res(padding-top,70px, 20 / 70);
    @include res(padding-bottom,110px, 10 / 110);
    .index-common-title{
        @include res(margin-bottom,70px, 20 / 70);
    }
    .brand-cont{
        @include res(padding-left,90px, 10 / 90);
        @include res(padding-right,90px, 10 / 90);
        .brand-one,.brand-two .item{
            position: relative;
            transition: all .3s;
            background-size: cover;
            background-position: center;
            overflow: hidden;
            @include res(padding-top,100px, 30 / 100);
            @include res(padding-bottom,210px, 30 / 210);
            @include res(padding-left,150px, 15 / 150);
            @include res(padding-right,150px, 15 / 150);
            &::after{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.4);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
            &::before{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0,127,138,.8);
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                transition: all .3s;
                opacity: 0;
            }
            &:hover{
                // box-shadow: 0 0 20px rgba(0,127,138,.4);
                // &::before{
                //     opacity: 1;
                // }
            }
            .left-shadow{
                width: 100%;
                height: 120%;
                background-color: $color-main-bg;
                position: absolute;
                right: 50%;
                top: -10%;
                z-index: 3;
                transform-origin: left;
            }
            .right-shadow{
                width: 100%;
                height: 120%;
                background-color: $color-main-bg;
                position: absolute;
                left: 50%;
                top: -10%;
                z-index: 3;
                transform-origin: right;
            }
            .cont{
                position: relative;
                z-index: 2;
                color: #fff;
                text-align: center;
                opacity: 0;
                transition: all .3s;
                .title{
                    font-weight: bold;
                    @include res(font-size,40px, 18 / 40);
                    @include res(margin-bottom,20px, 10 / 20);
                }
                .desc{
                    line-height: 1.6;
                    @include res(font-size,18px, 14 / 18);
                    @include res(margin-bottom,50px, 20 / 50);
                }
                .link{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    a{
                        display: inline-block;
                        border: 1px solid rgba(255,255,255,.5);
                        transition: all .3s;
                        white-space: nowrap;
                        flex-shrink: 0;
                        @include res(width,auto,(sm:30%));
                        @include res(flex-grow,null,(xs:1));
                        @include res(padding-left,40px, 5 / 40);
                        @include res(padding-right,40px, 5 / 40);
                        @include res(padding-top,15px, 10 / 15);
                        @include res(padding-bottom,15px, 10 / 15);
                        @include res(margin-left,7px, 3 / 7);
                        @include res(margin-right,7px, 3 / 7);
                        @include res(font-size,18px, 14 / 18);
                        @include res(margin-top,10px, 5 / 10);
                        &:hover{
                            border: 1px solid rgba(255,255,255,1);
                        }
                        .iconfont{
                            display: inline-block;
                            font-size: 12px;
                            margin-left: 5px;
                            transform: translateY(-30%) scale(0.9);
                        }
                    }
                }
            }
        }
        .brand-two{
            align-items: stretch;
            justify-content: space-between;
            @include res(display,flex,(sm:block));
            .item{
                flex-shrink: 0;
                @include res(width,49%,(sm:100%));
                @include res(padding-bottom,140px, 20 / 140);
                &:not(:first-child){
                    @include res(margin-top,0,(sm:20px,xs:10px));
                }
                .cont{
                    .link{
                        a{
                            flex-shrink: 0;
                            @include res(width,30%);
                            @include res(padding-left,5px);
                            @include res(padding-right,5px);
                        }
                        .t{
                            @include res(padding-top,35px, 20 / 35);
                            @include res(padding-bottom,35px, 20 / 35);
                        }
                    }
                }
            }
        }
    }
}

.index-map{
    background-color: #6A6A6A;
    color: #fff;
    .container{
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        .left-text{
            position: relative;
            flex-shrink: 0;
            box-sizing: border-box;
            @include res(background-color,#5C5C5C,(sm:#6A6A6A));
            @include res(width,26.6%,(md:30%,sm:100%));
            @include res(padding-top,100px, 20 / 100);
            @include res(padding-bottom,80px, 10 / 80);
            @include res(padding-right,70px, 0 / 70);
            &::after{
                content: '';
                width: 200%;
                height: 100%;
                background-color: #5C5C5C;
                position: absolute;
                top: 0;
                right: 100%;
                @include res(display,block,(sm:none));
            }
            .map-list{
                @include res(display,block,(sm:none));
                @include res(margin-top,60px, 10 / 60);
                ul{
                    border-top: 1px solid rgba(255,255,255,.36);
                    li{
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid rgba(255,255,255,.36);
                        transition: all .3s;
                        cursor: pointer;
                        @include res(padding-top,10px, 5 / 10);
                        @include res(padding-bottom,10px, 5 / 10);
                        @include res(padding-left,15px, 10 / 15);
                        @include res(padding-right,15px, 10 / 15);
                        span{
                            text-transform: uppercase;
                            font-size: 12px;
                            display: inline-block;
                            width: 6px;
                            @include res(margin-right,20px, 10 / 20);
                        }
                        &.active{
                            color: $color-main-yellow;
                        }
                    }
                }
            }
        }
        .right-map{
            flex-shrink: 0;
            box-sizing: border-box;
            @include res(width,73.4%,(md:70%,sm:100%));
            @include res(padding-top,95px, 10 / 95);
            @include res(padding-bottom,40px, 10 / 40);
            @include res(padding-left,75px, 0 / 75);
            @include res(padding-right,20px, 0 / 20);
            .map-cont{
                position: relative;
                .map-img{
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        opacity: .2;
                    }
                }
                .text-list{
                    ul{
                        &.vn_ul{
                            li{
                                position: absolute;
                                &:nth-child(1){
                                    top: 30%;
                                    left: 15%;
                                }
                                &:nth-child(2){
                                    top: 68%;
                                    left: 27%;
                                }
                                &:nth-child(3){
                                    top: 17%;
                                    left: 43.5%;
                                }
                                &:nth-child(4){
                                    top: 34.5%;
                                    left: 51%;
                                }
                                &:nth-child(5){
                                    top: 47.5%;
                                    left: 43%;
                                }
                                &:nth-child(6){
                                    top: 26.5%;
                                    left: 74.5%;
                                }
                                &:nth-child(7){
                                    top: 61.5%;
                                    left: 64.5%;
                                }
                                &:nth-child(8){
                                    top: 74.5%;
                                    left: 80.5%;
                                }
                            }
                        }
                        li{
                            position: absolute;
                            &:nth-child(1){
                                top: 30%;
                                left: 15%;
                            }
                            &:nth-child(2){
                                top: 68%;
                                left: 27%;
                            }
                            &:nth-child(3){
                                top: 17%;
                                left: 43.5%;
                            }
                            &:nth-child(4){
                                top: 34.5%;
                                left: 53%;
                            }
                            &:nth-child(5){
                                top: 54.5%;
                                left: 47%;
                            }
                            &:nth-child(6){
                                top: 26.5%;
                                left: 74.5%;
                            }
                            &:nth-child(7){
                                top: 57.5%;
                                left: 64.5%;
                            }
                            &:nth-child(8){
                                top: 74.5%;
                                left: 80.5%;
                            }
                        }
                    }
                }
                .map-list{
                    ul{
                        li{
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            border: 1px solid $color-main-yellow;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $color-main-yellow;
                            font-size: 12px;
                            text-transform: uppercase;
                            cursor: pointer;
                            transition: all .3s;
                            span{
                                transform: scale(.9);
                            }
                            &.active{
                                background-color: $color-main-yellow;
                                color: #000;
                            }
                            &:nth-child(1){
                                top: 40.7%;
                                left: 79.2%;
                            }
                            &:nth-child(2){
                                top: 40.3%;
                                left: 76.2%;
                            }
                            &:nth-child(3){
                                top: 49.3%;
                                left: 77.5%;
                            }
                            // &:nth-child(4){
                            //     top: 28.8%;
                            //     left: 45.7%;
                            // }
                            &:nth-child(4){
                                top: 30.8%;
                                left: 48.5%;
                            }
                            &:nth-child(5){
                                top: 26.2%;
                                left: 15.8%;
                            }
                            &:nth-child(6){
                                top: 57.2%;
                                left: 75%;
                            }
                            &:nth-child(7){
                                top: 55.2%;
                                left: 76.7%;
                            }
                            &:nth-child(8){
                                top: 53.5%;
                                left: 75%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.index-quality{
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position: relative;
    @include res(padding-top,300px, 100 / 300);
    &::after{
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        background-color: #fff;
        @include res(height,225px, 140 / 225);
    }
    .desc-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        .index-common-title{
            margin-bottom: 0;
        }
    }
    .cont{
        background-color: #fff;
        position: relative;
        z-index: 1;
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        @include res(margin-top,65px, 20 / 65);
        @include res(padding-left,100px, 10 / 100);
        @include res(padding-right,60px, 10 / 60);
        @include res(padding-top,95px, 45 / 95);
        @include res(padding-bottom,140px, 35 / 140);
        .left-text{
            flex-shrink: 0;
            @include res(width,47%,(sm:100%));
            .t{
                position: relative;
                line-height: 1.6;
                @include res(font-size,24px, 16 / 24);
                @include res(margin-bottom,35px, 10 / 35);
                @include res(margin-top,20px, 0 / 20);
                &::after{
                    content: '“';
                    font-family: 'Arial','roboto';
                    color: #e6f1f1;
                    position: absolute;
                    line-height: .1;
                    z-index: -1;
                    @include res(bottom,80%,(xs:90%));
                    @include res(right,95%,(xs:90%));
                    @include res(font-size,200px, 100 / 200);
                }
            }
            .link{
                text-align: right;
            }
        }
        .right-link{
            flex-shrink: 0;
            @include res(width,44%,(sm:100%));
            @include res(margin-top,0,(sm:20px));
            .link1{
                @include res(margin-bottom,30px, 10 / 30);
                a{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $color-line;
                    color: $color-main;
                    position: relative;
                    @include res(padding-top,15px, 10 / 15);
                    @include res(padding-bottom,15px, 10 / 15);
                    &::after{
                        content: '';
                        display: block;
                        width: 0;
                        height: 1px;
                        background-color: $color-main;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        transition: all .3s;
                    }
                    &:hover{
                        &::after{
                            width: 100%;
                        }
                        span{
                            opacity: 1;
                        }
                    }
                    span{
                        opacity: .8;
                        transition: all .3s;
                        @include res(font-size,22px, 16 / 22);
                    }
                    .iconfont{
                        @include res(font-size,22px, 18 / 22);
                    }
                }
            }
            .link2{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                a{
                    background-color: #f1f1f1;
                    margin: 1px;
                    transition: all .3s;
                    @include res(padding-top,12px, 5 / 12);
                    @include res(padding-bottom,12px, 5 / 12);
                    @include res(padding-left,20px, 10 / 20);
                    @include res(padding-right,20px, 10 / 20);
                    &:hover{
                        background-color: $color-main-bg;
                    }
                }
            }
        }
    }
}

.index-news{
    background-color: $color-main-bg;
    @include res(padding-top,80px, 20 / 80);
    @include res(padding-bottom,100px, 10 / 100);
    .news-swiper{
        position: relative;
        @include res(padding-left,50px, 0 / 50);
        @include res(padding-right,50px, 0 / 50);
        @include res(margin-top,60px, 20 / 60);
        .swiper-slide{
            background-color: #000;
            overflow: hidden;
            &:hover{
                b{
                    transform: scale(1.1);
                }
            }
            img{
                display: block;
                width: 100%;
                height: auto;
                opacity: 0;
            }
            b{
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                top: 0;
                left: 0;
                opacity: .8;
                transition: all .3s;
            }
            .item-text{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 1;
                color: #fff;
                background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.7));
                box-sizing: border-box;
                @include res(padding,25px, 10 / 25);
                .date{
                    border-bottom: 1px solid rgba(255,255,255,.6);
                    @include res(font-size,14px, 12 / 14);
                    @include res(padding-bottom,10px, 5 / 10);
                    @include res(margin-bottom,8px, 4 / 8);
                }
                .title{
                    line-height: 1.6;
                    font-weight: bold;
                    overflow : hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    @include res(min-height,64px, 50 / 64);
                    @include res(font-size,20px, 16 / 20);
                }
            }
        }
        .swiper-button-next,.swiper-button-prev{
            @include res(border,1px solid $color-main,(xs:1px solid #fff));
            &::after,&::before{
                color: $color-main;
                @include res(color,$color-main,(xs:#fff));
            }
            &:hover{
                background-color: $color-main;
                border:1px solid $color-main;
                &::after,&::before{
                    color: #fff;
                }
            }
        }
        .swiper-button-next{
            @include res(transform,translateX(50%),(mmd:translateX(70%),md:translateX(85%),sm:translateX(110%),xs:translateX(0)));
        }
        .swiper-button-prev{
            @include res(transform,translateX(-50%),(mmd:translateX(-70%),md:translateX(-85%),sm:translateX(-110%),xs:translateX(0)));
        }
    }
}