@charset "UTF-8";
.index-page .header:hover .header-cont, .index-page .header.white .header-cont {
  background-color: #fff;
  background-image: none;
  color: #585858;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
}

.index-page .header:hover .header-right .nav > ul > li .nav-title a, .index-page .header.white .header-right .nav > ul > li .nav-title a {
  color: #585858;
}

.index-page .header .header-cont {
  background-color: transparent;
  box-shadow: none;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  color: #fff;
  transition: all .3s;
}

.index-page .header .header-right .nav > ul > li .nav-title a {
  color: #fff;
}

.index-banner {
  position: relative;
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 800px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 600px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 300px;
  }
}

.index-banner .swiper-container {
  height: 100%;
}

.index-banner .swiper-container .swiper-slide {
  overflow: hidden;
}

.index-banner .swiper-container .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}

.index-banner .swiper-container .swiper-slide::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-banner .banner-text {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  color: #fff;
  font-weight: bold;
  transform: translateY(-50%);
  width: 100%;
  font-size: 70px;
  text-align: right;
}

@media (max-width: 1600px) {
  .index-banner .banner-text {
    font-size: 62px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text {
    font-size: 54px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text {
    font-size: 46px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text {
    font-size: 38px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text {
    text-align: center;
  }
}

.index-banner .swiper-contraller {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 3;
  bottom: 100px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-contraller {
    bottom: 86px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-contraller {
    bottom: 72px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-contraller {
    bottom: 58px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-contraller {
    bottom: 44px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-contraller {
    bottom: 30px;
  }
}

.index-banner .swiper-contraller .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.index-banner .swiper-contraller .container::before {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.index-banner .swiper-contraller .container .swiper-pagination {
  position: static;
  flex-shrink: 0;
  width: auto;
  color: #fff;
  margin-left: 10px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-contraller .container .swiper-pagination {
    margin-left: 10px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-contraller .container .swiper-pagination {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-contraller .container .swiper-pagination {
    margin-left: 10px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-contraller .container .swiper-pagination {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-contraller .container .swiper-pagination {
    margin-left: 10px;
  }
}

.index-banner .swiper-contraller .container .swiper-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-contraller .container .swiper-button {
    margin-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-contraller .container .swiper-button {
    margin-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-contraller .container .swiper-button {
    margin-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-contraller .container .swiper-button {
    margin-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-contraller .container .swiper-button {
    margin-left: 10px;
  }
}

.index-banner .swiper-contraller .container .swiper-button .swiper-button-prev, .index-banner .swiper-contraller .container .swiper-button .swiper-button-next {
  position: static;
  margin: 0;
  border: 1px solid #fff;
}

.index-banner .swiper-contraller .container .swiper-button .swiper-button-prev:hover, .index-banner .swiper-contraller .container .swiper-button .swiper-button-next:hover {
  background-color: transparent;
}

.index-banner .swiper-contraller .container .swiper-button .swiper-button-next {
  margin-left: 10px;
}

.index-search {
  background-color: #007b7b;
  padding-top: 70px;
}

@media (max-width: 1600px) {
  .index-search {
    padding-top: 56px;
  }
}

@media (max-width: 1366px) {
  .index-search {
    padding-top: 42px;
  }
}

@media (max-width: 1024px) {
  .index-search {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-search {
    padding-top: 14px;
  }
}

@media (max-width: 767px) {
  .index-search {
    padding-top: 0px;
  }
}

.index-search .search-cont {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-search .search-cont {
    display: block;
  }
}

.index-search .search-cont .left-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 50%;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form {
    padding-bottom: 10px;
  }
}

.index-search .search-cont .left-form .search-img {
  flex-shrink: 0;
  width: 35%;
  margin-right: 50px;
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form .search-img {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-search .search-cont .left-form .search-img {
    margin-right: 46px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .left-form .search-img {
    margin-right: 42px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .left-form .search-img {
    margin-right: 38px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form .search-img {
    margin-right: 34px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form .search-img {
    margin-right: 30px;
  }
}

.index-search .search-cont .left-form .search-img img {
  display: block;
  width: 100%;
  height: auto;
}

.index-search .search-cont .left-form .search-form {
  flex-grow: 1;
  padding-top: 45px;
}

@media (max-width: 1600px) {
  .index-search .search-cont .left-form .search-form {
    padding-top: 38px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .left-form .search-form {
    padding-top: 31px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .left-form .search-form {
    padding-top: 24px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form .search-form {
    padding-top: 17px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form .search-form {
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form .search-form .index-common-title {
    display: none;
  }
}

.index-search .search-cont .left-form .search-form form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 2px solid #fed900;
}

.index-search .search-cont .left-form .search-form form button {
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.index-search .search-cont .left-form .search-form form button .iconfont {
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-search .search-cont .left-form .search-form form button .iconfont {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .left-form .search-form form button .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .left-form .search-form form button .iconfont {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form .search-form form button .iconfont {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form .search-form form button .iconfont {
    font-size: 18px;
  }
}

.index-search .search-cont .left-form .search-form form input {
  flex-grow: 1;
  background: none;
  border: none;
  color: #fff;
  line-height: 50px;
}

@media (max-width: 1600px) {
  .index-search .search-cont .left-form .search-form form input {
    line-height: 48px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .left-form .search-form form input {
    line-height: 46px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .left-form .search-form form input {
    line-height: 44px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .left-form .search-form form input {
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .left-form .search-form form input {
    line-height: 40px;
  }
}

.index-search .search-cont .left-form .search-form form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.index-search .search-cont .left-form .search-form form input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.6);
}

.index-search .search-cont .left-form .search-form form input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.6);
}

.index-search .search-cont .left-form .search-form form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.6);
}

.index-search .search-cont .right-link {
  color: #fff;
  width: 45.7%;
  padding-bottom: 0;
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link {
    padding-bottom: 10px;
  }
}

.index-search .search-cont .right-link h1 {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link h1 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link h1 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link h1 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link h1 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link h1 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link h1 {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link h1 {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link h1 {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link h1 {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link h1 {
    margin-bottom: 10px;
  }
}

.index-search .search-cont .right-link .link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.index-search .search-cont .right-link .link a {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all .3s;
  margin-right: 8px;
  margin-bottom: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link .link a {
    margin-right: 7.4px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link .link a {
    margin-right: 6.8px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link .link a {
    margin-right: 6.2px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link .link a {
    margin-right: 5.6px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link .link a {
    margin-right: 5px;
  }
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link .link a {
    margin-bottom: 7.4px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link .link a {
    margin-bottom: 6.8px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link .link a {
    margin-bottom: 6.2px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link .link a {
    margin-bottom: 5.6px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link .link a {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link .link a {
    padding-left: 9.6px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link .link a {
    padding-left: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link .link a {
    padding-left: 8.8px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link .link a {
    padding-left: 8.4px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link .link a {
    padding-left: 8px;
  }
}

@media (max-width: 1600px) {
  .index-search .search-cont .right-link .link a {
    padding-right: 9.6px;
  }
}

@media (max-width: 1366px) {
  .index-search .search-cont .right-link .link a {
    padding-right: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-search .search-cont .right-link .link a {
    padding-right: 8.8px;
  }
}

@media (max-width: 991px) {
  .index-search .search-cont .right-link .link a {
    padding-right: 8.4px;
  }
}

@media (max-width: 767px) {
  .index-search .search-cont .right-link .link a {
    padding-right: 8px;
  }
}

.index-search .search-cont .right-link .link a:hover {
  border: 1px solid white;
}

.index-about {
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index-about {
    padding-bottom: 82px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-bottom: 64px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 46px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 10px;
  }
}

.index-about .cont1 {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index-about .cont1 {
    padding-top: 84px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 {
    padding-top: 68px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 {
    padding-top: 52px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 {
    padding-bottom: 84px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 {
    padding-bottom: 68px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 {
    padding-bottom: 52px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 {
    padding-bottom: 36px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 {
    padding-bottom: 20px;
  }
}

.index-about .cont1 .common-title span {
  color: #d4d4d4;
  font-size: 30px;
  margin-left: 20px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .common-title span {
    font-size: 27.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .common-title span {
    font-size: 24.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .common-title span {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .common-title span {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .common-title span {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .common-title span {
    margin-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .common-title span {
    margin-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .common-title span {
    margin-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .common-title span {
    margin-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .common-title span {
    margin-left: 10px;
  }
}

.index-about .cont1 .left-text {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text {
    display: block;
  }
}

.index-about .cont1 .left-text .text {
  flex-grow: 1;
  line-height: 2;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-about .cont1 .left-text .text {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .left-text .text {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .left-text .text {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .text {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .left-text .text {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .left-text .text {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .left-text .text {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .left-text .text {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .text {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .left-text .text {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .left-text .text {
    margin-top: 8px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .left-text .text {
    margin-top: 6px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .left-text .text {
    margin-top: 4px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .text {
    margin-top: 2px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .left-text .text {
    margin-top: 0px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .left-text .text {
    margin-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .left-text .text {
    margin-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .left-text .text {
    margin-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .text {
    margin-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .left-text .text {
    margin-bottom: 10px;
  }
}

.index-about .cont1 .left-text .link {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  width: auto;
  margin-left: 10%;
  max-width: 180px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .link {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .link {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .link {
    max-width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont1 .left-text .link {
    margin-top: 16px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont1 .left-text .link {
    margin-top: 12px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont1 .left-text .link {
    margin-top: 8px;
  }
}

@media (max-width: 991px) {
  .index-about .cont1 .left-text .link {
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .index-about .cont1 .left-text .link {
    margin-top: 0px;
  }
}

.index-about .cont2 {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-about .cont2 {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 {
    margin-bottom: 20px;
  }
}

.index-about .cont2 .item {
  flex-shrink: 0;
  width: 22%;
  margin-top: 0;
}

@media (max-width: 767px) {
  .index-about .cont2 .item {
    width: 47%;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item {
    margin-top: 15px;
  }
}

.index-about .cont2 .item .number-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #007b7b;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-top {
    padding-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-top {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-top {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-top {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-top {
    padding-bottom: 5px;
  }
}

.index-about .cont2 .item .number-top .number {
  display: flex;
  align-items: center;
  color: #007b7b;
}

.index-about .cont2 .item .number-top .number .jia {
  line-height: 60px;
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-top .number .jia {
    line-height: 54px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-top .number .jia {
    line-height: 48px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-top .number .jia {
    line-height: 42px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-top .number .jia {
    line-height: 36px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-top .number .jia {
    line-height: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-top .number .jia {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-top .number .jia {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-top .number .jia {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-top .number .jia {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-top .number .jia {
    font-size: 30px;
  }
}

.index-about .cont2 .item .number-top .dw {
  margin-top: 10px;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-top .dw {
    margin-top: 9px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-top .dw {
    margin-top: 8px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-top .dw {
    margin-top: 7px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-top .dw {
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-top .dw {
    margin-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-top .dw {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-top .dw {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-top .dw {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-top .dw {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-top .dw {
    font-size: 14px;
  }
}

.index-about .cont2 .item .number-bottom {
  padding-top: 12px;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-bottom {
    padding-top: 11px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-bottom {
    padding-top: 10px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-bottom {
    padding-top: 9px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-bottom {
    padding-top: 8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-bottom {
    padding-top: 7px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont2 .item .number-bottom {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont2 .item .number-bottom {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont2 .item .number-bottom {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-about .cont2 .item .number-bottom {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-about .cont2 .item .number-bottom {
    font-size: 16px;
  }
}

.index-about .cont3 {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-about .cont3 {
    display: block;
  }
}

.index-about .cont3 .item {
  flex-shrink: 0;
  position: relative;
  background-color: #000;
  overflow: hidden;
  width: 33.33%;
}

@media (max-width: 767px) {
  .index-about .cont3 .item {
    width: 100%;
  }
}

.index-about .cont3 .item img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.index-about .cont3 .item b {
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: .8;
  transition: all .3s;
}

.index-about .cont3 .item .text {
  position: absolute;
  z-index: 2;
  color: #fff;
  overflow: hidden;
  top: 45%;
  padding-left: 35px;
  padding-right: 35px;
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text {
    top: 20%;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text {
    top: 50%;
  }
}

@media (max-width: 1600px) {
  .index-about .cont3 .item .text {
    padding-left: 31px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont3 .item .text {
    padding-left: 27px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont3 .item .text {
    padding-left: 23px;
  }
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text {
    padding-left: 19px;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont3 .item .text {
    padding-right: 31px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont3 .item .text {
    padding-right: 27px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont3 .item .text {
    padding-right: 23px;
  }
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text {
    padding-right: 19px;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text {
    padding-right: 15px;
  }
}

.index-about .cont3 .item .text .title {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-about .cont3 .item .text .title {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont3 .item .text .title {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont3 .item .text .title {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text .title {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text .title {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont3 .item .text .title {
    font-size: 27.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont3 .item .text .title {
    font-size: 25.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont3 .item .text .title {
    font-size: 22.8px;
  }
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text .title {
    font-size: 20.4px;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text .title {
    font-size: 18px;
  }
}

.index-about .cont3 .item .text .t {
  line-height: 1.6;
  transition: all .3s;
  transform: translateY(100%);
  font-size: 18px;
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text .t {
    transform: translateY(0);
  }
}

@media (max-width: 1600px) {
  .index-about .cont3 .item .text .t {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont3 .item .text .t {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont3 .item .text .t {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont3 .item .text .t {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont3 .item .text .t {
    font-size: 14px;
  }
}

.index-about .cont3 .item:hover b {
  transform: scale(1.1);
}

.index-about .cont3 .item:hover .text .t {
  transform: translateY(0);
}

.index-brand {
  background-color: #f7f7f7;
  padding-top: 70px;
  padding-bottom: 110px;
}

@media (max-width: 1600px) {
  .index-brand {
    padding-top: 60px;
  }
}

@media (max-width: 1366px) {
  .index-brand {
    padding-top: 50px;
  }
}

@media (max-width: 1024px) {
  .index-brand {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .index-brand {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .index-brand {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-brand {
    padding-bottom: 90px;
  }
}

@media (max-width: 1366px) {
  .index-brand {
    padding-bottom: 70px;
  }
}

@media (max-width: 1024px) {
  .index-brand {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .index-brand {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-brand {
    padding-bottom: 10px;
  }
}

.index-brand .index-common-title {
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-brand .index-common-title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-brand .index-common-title {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-brand .index-common-title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-brand .index-common-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-brand .index-common-title {
    margin-bottom: 20px;
  }
}

.index-brand .brand-cont {
  padding-left: 90px;
  padding-right: 90px;
}

@media (max-width: 1600px) {
  .index-brand .brand-cont {
    padding-left: 74px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont {
    padding-left: 58px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont {
    padding-left: 42px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont {
    padding-left: 26px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont {
    padding-right: 74px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont {
    padding-right: 58px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont {
    padding-right: 42px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont {
    padding-right: 26px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont {
    padding-right: 10px;
  }
}

.index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
  position: relative;
  transition: all .3s;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 210px;
  padding-left: 150px;
  padding-right: 150px;
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-top: 86px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-top: 72px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-top: 58px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-top: 44px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-bottom: 174px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-bottom: 138px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-bottom: 102px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-bottom: 66px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-left: 123px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-left: 96px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-left: 69px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-left: 42px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-right: 123px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-right: 96px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-right: 69px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-right: 42px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one, .index-brand .brand-cont .brand-two .item {
    padding-right: 15px;
  }
}

.index-brand .brand-cont .brand-one::after, .index-brand .brand-cont .brand-two .item::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.index-brand .brand-cont .brand-one::before, .index-brand .brand-cont .brand-two .item::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 127, 138, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all .3s;
  opacity: 0;
}

.index-brand .brand-cont .brand-one .left-shadow, .index-brand .brand-cont .brand-two .item .left-shadow {
  width: 100%;
  height: 120%;
  background-color: #f7f7f7;
  position: absolute;
  right: 50%;
  top: -10%;
  z-index: 3;
  transform-origin: left;
}

.index-brand .brand-cont .brand-one .right-shadow, .index-brand .brand-cont .brand-two .item .right-shadow {
  width: 100%;
  height: 120%;
  background-color: #f7f7f7;
  position: absolute;
  left: 50%;
  top: -10%;
  z-index: 3;
  transform-origin: right;
}

.index-brand .brand-cont .brand-one .cont, .index-brand .brand-cont .brand-two .item .cont {
  position: relative;
  z-index: 2;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: all .3s;
}

.index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    font-size: 35.6px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    font-size: 26.8px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .title, .index-brand .brand-cont .brand-two .item .cont .title {
    margin-bottom: 10px;
  }
}

.index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
  line-height: 1.6;
  font-size: 18px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .desc, .index-brand .brand-cont .brand-two .item .cont .desc {
    margin-bottom: 20px;
  }
}

.index-brand .brand-cont .brand-one .cont .link, .index-brand .brand-cont .brand-two .item .cont .link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all .3s;
  white-space: nowrap;
  flex-shrink: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 7px;
  margin-right: 7px;
  font-size: 18px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    flex-grow: 1;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-left: 33px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-left: 26px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-left: 19px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-left: 5px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-right: 33px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-right: 26px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-right: 19px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-right: 5px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-top: 14px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-top: 13px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-top: 12px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-top: 11px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-left: 6.2px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-left: 5.4px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-left: 4.6px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-left: 3.8px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-left: 3px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-right: 6.2px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-right: 5.4px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-right: 4.6px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-right: 3.8px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-right: 3px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-top: 9px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-top: 8px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-top: 7px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-top: 6px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-one .cont .link a, .index-brand .brand-cont .brand-two .item .cont .link a {
    margin-top: 5px;
  }
}

.index-brand .brand-cont .brand-one .cont .link a:hover, .index-brand .brand-cont .brand-two .item .cont .link a:hover {
  border: 1px solid white;
}

.index-brand .brand-cont .brand-one .cont .link a .iconfont, .index-brand .brand-cont .brand-two .item .cont .link a .iconfont {
  display: inline-block;
  font-size: 12px;
  margin-left: 5px;
  transform: translateY(-30%) scale(0.9);
}

.index-brand .brand-cont .brand-two {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two {
    display: block;
  }
}

.index-brand .brand-cont .brand-two .item {
  flex-shrink: 0;
  width: 49%;
  padding-bottom: 140px;
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two .item {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-two .item {
    padding-bottom: 116px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-two .item {
    padding-bottom: 92px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-two .item {
    padding-bottom: 68px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two .item {
    padding-bottom: 44px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-two .item {
    padding-bottom: 20px;
  }
}

.index-brand .brand-cont .brand-two .item:not(:first-child) {
  margin-top: 0;
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two .item:not(:first-child) {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-two .item:not(:first-child) {
    margin-top: 10px;
  }
}

.index-brand .brand-cont .brand-two .item .cont .link a {
  flex-shrink: 0;
  width: 30%;
  padding-left: 5px;
  padding-right: 5px;
}

.index-brand .brand-cont .brand-two .item .cont .link .t {
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-top: 32px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-top: 29px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-top: 26px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-top: 23px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-bottom: 32px;
  }
}

@media (max-width: 1366px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-bottom: 29px;
  }
}

@media (max-width: 1024px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-bottom: 26px;
  }
}

@media (max-width: 991px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-bottom: 23px;
  }
}

@media (max-width: 767px) {
  .index-brand .brand-cont .brand-two .item .cont .link .t {
    padding-bottom: 20px;
  }
}

.index-map {
  background-color: #6A6A6A;
  color: #fff;
}

.index-map .container {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 991px) {
  .index-map .container {
    display: block;
  }
}

.index-map .container .left-text {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #5C5C5C;
  width: 26.6%;
  padding-top: 100px;
  padding-bottom: 80px;
  padding-right: 70px;
}

@media (max-width: 991px) {
  .index-map .container .left-text {
    background-color: #6A6A6A;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text {
    width: 30%;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text {
    padding-top: 84px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text {
    padding-top: 68px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text {
    padding-top: 52px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text {
    padding-bottom: 66px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text {
    padding-bottom: 52px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text {
    padding-bottom: 38px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text {
    padding-right: 56px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text {
    padding-right: 42px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text {
    padding-right: 28px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text {
    padding-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text {
    padding-right: 0px;
  }
}

.index-map .container .left-text::after {
  content: '';
  width: 200%;
  height: 100%;
  background-color: #5C5C5C;
  position: absolute;
  top: 0;
  right: 100%;
  display: block;
}

@media (max-width: 991px) {
  .index-map .container .left-text::after {
    display: none;
  }
}

.index-map .container .left-text .map-list {
  display: block;
  margin-top: 60px;
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list {
    margin-top: 50px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list {
    margin-top: 10px;
  }
}

.index-map .container .left-text .map-list ul {
  border-top: 1px solid rgba(255, 255, 255, 0.36);
}

.index-map .container .left-text .map-list ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(255, 255, 255, 0.36);
  transition: all .3s;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list ul li {
    padding-top: 9px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list ul li {
    padding-top: 8px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list ul li {
    padding-top: 7px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list ul li {
    padding-top: 6px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list ul li {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list ul li {
    padding-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list ul li {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list ul li {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list ul li {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list ul li {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list ul li {
    padding-left: 14px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list ul li {
    padding-left: 13px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list ul li {
    padding-left: 12px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list ul li {
    padding-left: 11px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list ul li {
    padding-right: 14px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list ul li {
    padding-right: 13px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list ul li {
    padding-right: 12px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list ul li {
    padding-right: 11px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list ul li {
    padding-right: 10px;
  }
}

.index-map .container .left-text .map-list ul li span {
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  width: 6px;
  margin-right: 20px;
}

@media (max-width: 1600px) {
  .index-map .container .left-text .map-list ul li span {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .left-text .map-list ul li span {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .left-text .map-list ul li span {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-map .container .left-text .map-list ul li span {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-map .container .left-text .map-list ul li span {
    margin-right: 10px;
  }
}

.index-map .container .left-text .map-list ul li.active {
  color: #fed900;
}

.index-map .container .right-map {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 73.4%;
  padding-top: 95px;
  padding-bottom: 40px;
  padding-left: 75px;
  padding-right: 20px;
}

@media (max-width: 1024px) {
  .index-map .container .right-map {
    width: 70%;
  }
}

@media (max-width: 991px) {
  .index-map .container .right-map {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-map .container .right-map {
    padding-top: 78px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .right-map {
    padding-top: 61px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .right-map {
    padding-top: 44px;
  }
}

@media (max-width: 991px) {
  .index-map .container .right-map {
    padding-top: 27px;
  }
}

@media (max-width: 767px) {
  .index-map .container .right-map {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .right-map {
    padding-bottom: 34px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .right-map {
    padding-bottom: 28px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .right-map {
    padding-bottom: 22px;
  }
}

@media (max-width: 991px) {
  .index-map .container .right-map {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .index-map .container .right-map {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .right-map {
    padding-left: 60px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .right-map {
    padding-left: 45px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .right-map {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .index-map .container .right-map {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .index-map .container .right-map {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-map .container .right-map {
    padding-right: 16px;
  }
}

@media (max-width: 1366px) {
  .index-map .container .right-map {
    padding-right: 12px;
  }
}

@media (max-width: 1024px) {
  .index-map .container .right-map {
    padding-right: 8px;
  }
}

@media (max-width: 991px) {
  .index-map .container .right-map {
    padding-right: 4px;
  }
}

@media (max-width: 767px) {
  .index-map .container .right-map {
    padding-right: 0px;
  }
}

.index-map .container .right-map .map-cont {
  position: relative;
}

.index-map .container .right-map .map-cont .map-img img {
  display: block;
  width: 100%;
  height: auto;
  opacity: .2;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li {
  position: absolute;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(1) {
  top: 30%;
  left: 15%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(2) {
  top: 68%;
  left: 27%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(3) {
  top: 17%;
  left: 43.5%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(4) {
  top: 34.5%;
  left: 51%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(5) {
  top: 47.5%;
  left: 43%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(6) {
  top: 26.5%;
  left: 74.5%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(7) {
  top: 61.5%;
  left: 64.5%;
}

.index-map .container .right-map .map-cont .text-list ul.vn_ul li:nth-child(8) {
  top: 74.5%;
  left: 80.5%;
}

.index-map .container .right-map .map-cont .text-list ul li {
  position: absolute;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(1) {
  top: 30%;
  left: 15%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(2) {
  top: 68%;
  left: 27%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(3) {
  top: 17%;
  left: 43.5%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(4) {
  top: 34.5%;
  left: 53%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(5) {
  top: 54.5%;
  left: 47%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(6) {
  top: 26.5%;
  left: 74.5%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(7) {
  top: 57.5%;
  left: 64.5%;
}

.index-map .container .right-map .map-cont .text-list ul li:nth-child(8) {
  top: 74.5%;
  left: 80.5%;
}

.index-map .container .right-map .map-cont .map-list ul li {
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid #fed900;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fed900;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s;
}

.index-map .container .right-map .map-cont .map-list ul li span {
  transform: scale(0.9);
}

.index-map .container .right-map .map-cont .map-list ul li.active {
  background-color: #fed900;
  color: #000;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(1) {
  top: 40.7%;
  left: 79.2%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(2) {
  top: 40.3%;
  left: 76.2%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(3) {
  top: 49.3%;
  left: 77.5%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(4) {
  top: 30.8%;
  left: 48.5%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(5) {
  top: 26.2%;
  left: 15.8%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(6) {
  top: 57.2%;
  left: 75%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(7) {
  top: 55.2%;
  left: 76.7%;
}

.index-map .container .right-map .map-cont .map-list ul li:nth-child(8) {
  top: 53.5%;
  left: 75%;
}

.index-quality {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 300px;
}

@media (max-width: 1600px) {
  .index-quality {
    padding-top: 260px;
  }
}

@media (max-width: 1366px) {
  .index-quality {
    padding-top: 220px;
  }
}

@media (max-width: 1024px) {
  .index-quality {
    padding-top: 180px;
  }
}

@media (max-width: 991px) {
  .index-quality {
    padding-top: 140px;
  }
}

@media (max-width: 767px) {
  .index-quality {
    padding-top: 100px;
  }
}

.index-quality::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: #fff;
  height: 225px;
}

@media (max-width: 1600px) {
  .index-quality::after {
    height: 208px;
  }
}

@media (max-width: 1366px) {
  .index-quality::after {
    height: 191px;
  }
}

@media (max-width: 1024px) {
  .index-quality::after {
    height: 174px;
  }
}

@media (max-width: 991px) {
  .index-quality::after {
    height: 157px;
  }
}

@media (max-width: 767px) {
  .index-quality::after {
    height: 140px;
  }
}

.index-quality .desc-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.index-quality .desc-text .index-common-title {
  margin-bottom: 0;
}

.index-quality .cont {
  background-color: #fff;
  position: relative;
  z-index: 1;
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  margin-top: 65px;
  padding-left: 100px;
  padding-right: 60px;
  padding-top: 95px;
  padding-bottom: 140px;
}

@media (max-width: 991px) {
  .index-quality .cont {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont {
    margin-top: 56px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont {
    margin-top: 47px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont {
    margin-top: 38px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont {
    margin-top: 29px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont {
    padding-left: 82px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont {
    padding-left: 64px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont {
    padding-left: 46px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont {
    padding-right: 50px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont {
    padding-right: 40px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont {
    padding-top: 85px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont {
    padding-top: 75px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont {
    padding-top: 65px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont {
    padding-top: 55px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont {
    padding-top: 45px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont {
    padding-bottom: 119px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont {
    padding-bottom: 98px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont {
    padding-bottom: 77px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont {
    padding-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont {
    padding-bottom: 35px;
  }
}

.index-quality .cont .left-text {
  flex-shrink: 0;
  width: 47%;
}

@media (max-width: 991px) {
  .index-quality .cont .left-text {
    width: 100%;
  }
}

.index-quality .cont .left-text .t {
  position: relative;
  line-height: 1.6;
  font-size: 24px;
  margin-bottom: 35px;
  margin-top: 20px;
}

@media (max-width: 1600px) {
  .index-quality .cont .left-text .t {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .left-text .t {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .left-text .t {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .left-text .t {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .left-text .t {
    margin-bottom: 30px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .left-text .t {
    margin-bottom: 25px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .left-text .t {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .left-text .t {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .left-text .t {
    margin-top: 16px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .left-text .t {
    margin-top: 12px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .left-text .t {
    margin-top: 8px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .left-text .t {
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t {
    margin-top: 0px;
  }
}

.index-quality .cont .left-text .t::after {
  content: '“';
  font-family: 'Arial','roboto';
  color: #e6f1f1;
  position: absolute;
  line-height: .1;
  z-index: -1;
  bottom: 80%;
  right: 95%;
  font-size: 200px;
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t::after {
    bottom: 90%;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t::after {
    right: 90%;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .left-text .t::after {
    font-size: 180px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .left-text .t::after {
    font-size: 160px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .left-text .t::after {
    font-size: 140px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .left-text .t::after {
    font-size: 120px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .left-text .t::after {
    font-size: 100px;
  }
}

.index-quality .cont .left-text .link {
  text-align: right;
}

.index-quality .cont .right-link {
  flex-shrink: 0;
  width: 44%;
  margin-top: 0;
}

@media (max-width: 991px) {
  .index-quality .cont .right-link {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link {
    margin-top: 20px;
  }
}

.index-quality .cont .right-link .link1 {
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link1 {
    margin-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link1 {
    margin-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link1 {
    margin-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link1 {
    margin-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link1 {
    margin-bottom: 10px;
  }
}

.index-quality .cont .right-link .link1 a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  color: #007b7b;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link1 a {
    padding-top: 14px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link1 a {
    padding-top: 13px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link1 a {
    padding-top: 12px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link1 a {
    padding-top: 11px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link1 a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link1 a {
    padding-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link1 a {
    padding-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link1 a {
    padding-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link1 a {
    padding-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link1 a {
    padding-bottom: 10px;
  }
}

.index-quality .cont .right-link .link1 a::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background-color: #007b7b;
  position: absolute;
  bottom: -1px;
  left: 0;
  transition: all .3s;
}

.index-quality .cont .right-link .link1 a:hover::after {
  width: 100%;
}

.index-quality .cont .right-link .link1 a:hover span {
  opacity: 1;
}

.index-quality .cont .right-link .link1 a span {
  opacity: .8;
  transition: all .3s;
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link1 a span {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link1 a span {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link1 a span {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link1 a span {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link1 a span {
    font-size: 16px;
  }
}

.index-quality .cont .right-link .link1 a .iconfont {
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link1 a .iconfont {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link1 a .iconfont {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link1 a .iconfont {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link1 a .iconfont {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link1 a .iconfont {
    font-size: 18px;
  }
}

.index-quality .cont .right-link .link2 {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.index-quality .cont .right-link .link2 a {
  background-color: #f1f1f1;
  margin: 1px;
  transition: all .3s;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link2 a {
    padding-top: 10.6px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link2 a {
    padding-top: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link2 a {
    padding-top: 7.8px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link2 a {
    padding-top: 6.4px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link2 a {
    padding-top: 5px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link2 a {
    padding-bottom: 10.6px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link2 a {
    padding-bottom: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link2 a {
    padding-bottom: 7.8px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link2 a {
    padding-bottom: 6.4px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link2 a {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link2 a {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link2 a {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link2 a {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link2 a {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link2 a {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-quality .cont .right-link .link2 a {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-quality .cont .right-link .link2 a {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-quality .cont .right-link .link2 a {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-quality .cont .right-link .link2 a {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-quality .cont .right-link .link2 a {
    padding-right: 10px;
  }
}

.index-quality .cont .right-link .link2 a:hover {
  background-color: #f7f7f7;
}

.index-news {
  background-color: #f7f7f7;
  padding-top: 80px;
  padding-bottom: 100px;
}

@media (max-width: 1600px) {
  .index-news {
    padding-top: 68px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-top: 56px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-top: 44px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-top: 32px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news {
    padding-bottom: 82px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-bottom: 64px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-bottom: 46px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-bottom: 10px;
  }
}

.index-news .news-swiper {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper {
    padding-left: 40px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper {
    padding-left: 30px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper {
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper {
    padding-right: 40px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper {
    padding-right: 30px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper {
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper {
    padding-right: 0px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper {
    margin-top: 52px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper {
    margin-top: 44px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper {
    margin-top: 36px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper {
    margin-top: 28px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper {
    margin-top: 20px;
  }
}

.index-news .news-swiper .swiper-slide {
  background-color: #000;
  overflow: hidden;
}

.index-news .news-swiper .swiper-slide:hover b {
  transform: scale(1.1);
}

.index-news .news-swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.index-news .news-swiper .swiper-slide b {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;
  opacity: .8;
  transition: all .3s;
}

.index-news .news-swiper .swiper-slide .item-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  box-sizing: border-box;
  padding: 25px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text {
    padding: 22px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text {
    padding: 19px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text {
    padding: 16px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text {
    padding: 13px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text {
    padding: 10px;
  }
}

.index-news .news-swiper .swiper-slide .item-text .date {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding-bottom: 10px;
  margin-bottom: 8px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    font-size: 12px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    padding-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    padding-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    padding-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    padding-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    padding-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    margin-bottom: 7.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    margin-bottom: 6.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    margin-bottom: 5.6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    margin-bottom: 4.8px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text .date {
    margin-bottom: 4px;
  }
}

.index-news .news-swiper .swiper-slide .item-text .title {
  line-height: 1.6;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 64px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    min-height: 61.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    min-height: 58.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    min-height: 55.6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    min-height: 52.8px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    min-height: 50px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-slide .item-text .title {
    font-size: 16px;
  }
}

.index-news .news-swiper .swiper-button-next, .index-news .news-swiper .swiper-button-prev {
  border: 1px solid #007b7b;
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-button-next, .index-news .news-swiper .swiper-button-prev {
    border: 1px solid #fff;
  }
}

.index-news .news-swiper .swiper-button-next::after, .index-news .news-swiper .swiper-button-next::before, .index-news .news-swiper .swiper-button-prev::after, .index-news .news-swiper .swiper-button-prev::before {
  color: #007b7b;
  color: #007b7b;
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-button-next::after, .index-news .news-swiper .swiper-button-next::before, .index-news .news-swiper .swiper-button-prev::after, .index-news .news-swiper .swiper-button-prev::before {
    color: #fff;
  }
}

.index-news .news-swiper .swiper-button-next:hover, .index-news .news-swiper .swiper-button-prev:hover {
  background-color: #007b7b;
  border: 1px solid #007b7b;
}

.index-news .news-swiper .swiper-button-next:hover::after, .index-news .news-swiper .swiper-button-next:hover::before, .index-news .news-swiper .swiper-button-prev:hover::after, .index-news .news-swiper .swiper-button-prev:hover::before {
  color: #fff;
}

.index-news .news-swiper .swiper-button-next {
  transform: translateX(50%);
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-button-next {
    transform: translateX(70%);
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-button-next {
    transform: translateX(85%);
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-button-next {
    transform: translateX(110%);
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-button-next {
    transform: translateX(0);
  }
}

.index-news .news-swiper .swiper-button-prev {
  transform: translateX(-50%);
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper-button-prev {
    transform: translateX(-70%);
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper-button-prev {
    transform: translateX(-85%);
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper-button-prev {
    transform: translateX(-110%);
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper-button-prev {
    transform: translateX(0);
  }
}
